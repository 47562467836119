import React, { useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet-async'
import get from 'lodash/get'

import Layout from '../components/layout'

import Footer from '../components/global-footer'
import Header from '../components/global-header'

import Cover from '../components/cover'

const SiteRoot = () => {
    const data = useStaticQuery(graphql`
        {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    const siteTitle = get(data, 'site.siteMetadata.title')

    const footerRef = useRef(null)

    return (
        <Layout>
            <Helmet>
                <title>{`${siteTitle}`}</title>
                <meta property="og:title" content={siteTitle} />
            </Helmet>
            <Header />
            <main>
                <Cover scrollRef={footerRef} />
            </main>
            <Footer scrollRef={footerRef} />
        </Layout>
    )
}

export default SiteRoot
