import React from 'react'
import styled, { css } from 'styled-components'

import Theme from './shared/colors'
import { BREAKPOINTS } from './shared/grid'
import Spacing from './shared/spacing'

import { H1, typestyle } from './shared/typography'

const Cover = ({ heading, text, scrollRef }) => {
    const handleOnClick = (event) => {
        scrollRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
        })
    }

    return (
        <Header>
            <ArrowIcon src={'./arrow-right.svg'} />
            <Heading size={'cover'}><span>{heading}</span> {text}</Heading>
        </Header>
    )
}

const Header = styled.header`
    display: block;
    flex-direction: row;
    justify-content: center;
    padding: 0 0 10vw 0;
    text-align: left;
    color: ${Theme.text.primary};

    @media ${BREAKPOINTS.mobile} {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 10vw 0;
    }
`

const Heading = styled(H1)`
    flex: 3 3;
    padding: 0 10vw;

    span {
        display: block;
        color: ${Theme.brand.primary};
    }
`

const ArrowIcon = styled.img`
    flex: 1 1;
    margin: 0 auto;
    width: 30vw;

    @media ${BREAKPOINTS.mobile} {
        width: 14vw;
    }
`

const LogoIcon = styled.img`
    margin: 0 auto;
    max-width: 100%;
    max-height: 40vh;
`

const Button = styled.button`
    margin: ${Spacing.xl} 0;
    ${(props) => {
        props.size = props.size ? props.size : 'body'
        return typestyle()
    }}
    background-color: transparent;
    border: none;
    color: ${Theme.text.brand};

    &:focus {
        outline: 0;
    }
`

const Icon = styled.img`
    position: absolute;
    bottom: 2rem;
    left: 50%;
    width: 4rem;
    transform: translateX(-2rem);
`

export default Cover
